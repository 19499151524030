import React from 'react';
import {graphql} from 'gatsby';
import { Row, Col } from 'antd';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import {Page} from '../contracts/page';

import '../styles/text.scss';
import '../styles/iframe.scss';

export interface Props {
	data: {
		wpPage: Page;
	};
	location: Location;
}

const BookingFrame = React.lazy(() => import('../components/BookingFrame'));

export const IframePage = (props: Props, location: Location) => {
	const isSSR = typeof window === 'undefined';
	const page = props.data.wpPage;
	const params = new URLSearchParams(location.search);
	const paramsArr = props.location.search.split('&');
	const collectionDate = decodeURI(params.get('collection_date') ?? paramsArr[1]);
	const collectionTime = decodeURI(params.get('collection_time') ?? paramsArr[2]);
	const returnDate = decodeURI(params.get('return_date') ?? paramsArr[3]);
	const returnTime = decodeURI(params.get('return_time') ?? paramsArr[4]);
	const type = (paramsArr[0].indexOf('?type=') !== -1) ? paramsArr[0] : decodeURI(params.get('type') ?? paramsArr[6]);
	const url = page.iframe.iframeUrl + '?branch=1&age_selector=0';
	return (
		<Layout location={props.location}>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Row type="flex">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id="primary" className="content-area c_text--white">
					<div className="iframe">
						<Row type="flex" className="c_text page-gutter">
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<h1>{page.title}</h1>
								<div dangerouslySetInnerHTML={{__html: page.content}}/>
							</Col>
						</Row>
						{(page.iframe && page.iframe.iframeUrl) ? (
							<Row type="flex" className="c_iframe page-gutter">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
									{!isSSR && (
										<React.Suspense fallback={<div>Loading...</div>}>
											<BookingFrame url={url} type={type} returnTime={returnTime} returnDate={returnDate} collectionTime={collectionTime} collectionDate={collectionDate} location={props.location}/>
										</React.Suspense>
									)}
								</Col>
							</Row>
						) : ''}
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default IframePage;

export const query = graphql`
	query($id: String!) {
      wpPage(id: { eq: $id }) {
        title
        content
        iframe {
          iframeUrl
        }
		seo {
		  metaDesc
		  metaKeywords
		  metaRobotsNoindex
		  metaRobotsNofollow
		  opengraphAuthor
		  opengraphDescription
		  opengraphModifiedTime
		  opengraphPublishedTime
		  opengraphPublisher
		  opengraphSiteName
		  opengraphTitle
		  opengraphType
		  opengraphUrl
		  readingTime
		  title
		  twitterTitle
		  schema {
			articleType
			pageType
			raw
		  }
		  twitterDescription
		  twitterImage {
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
      }
    }
`;
